<template>
    <div class="ConfirmedList">
        <main-header menuActive="confirmed" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>확진자관리</h2>
                </div>
                <div class="row searchBtn">
                    <div class="link_tab pull-left">
                        <search-tab :searchTabData.sync="sort_data" :searchTabVal.sync="sort_key" />
                    </div>
                    <div class="pull-right">
                        <a v-if="['G1106', 'G1105'].includes(userDataAdminPermission)"
                                class="btn10"
                           @click="searchDetailFunc()"
                        >
                            환자등록
                        </a> &nbsp;
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>

                       <!-- <a class="btn8" @click="excelSympDownFunc" style="color:#fff">중증도엑셀</a> -->
                    </div>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">접수일</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_reg_start_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_reg_end_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>


                            </div>

                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">확진일</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_confirm_start_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_confirm_end_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>

                            </div>

                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">배정일</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_assign_start_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_assign_end_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="row pdd-top-10">
                            <div class="searchBox">
                              <div class="SearchCombo inline-block">
                                <label class="searchTitle">성별</label>
                                <label v-for="(data, index) in list_sex" :key="index"
                                       class="mrg-right-10"
                                >
                                  <input type="radio"
                                         :value="data.code"
                                         v-model="sch_sex"
                                  />
                                  {{data.name}}
                                </label>
                              </div>
                            </div>
                            <div class="searchBox">
                                <div class="SearchCombo inline-block">
                                    <label class="searchTitle">배정대상</label>
                                    <label v-for="(data, index) in list_assign_type" :key="index"
                                           class="mrg-right-10"
                                    >
                                        <input type="radio"
                                               :value="data.code"
                                               v-model="sch_assign_type"
                                        />
                                        {{data.name}}
                                    </label>
                                </div>
                            </div>
                            <div class="searchBox">
                                <div class="SearchCombo inline-block">
                                    <label class="searchTitle">시설별</label>
                                    <select class="select_ansimi width150"
                                            v-model="sch_fasility"
                                    >
                                        <option value="">전체</option>
                                        <option v-for="(data, index) in list_fasility" :key="index" :value="data.code">
                                            {{data.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="searchBox">
                                <div class="SearchCombo inline-block">
                                    <label class="searchTitle">지역별</label>
                                    <select class="select_ansimi width150"
                                            v-model="sch_region"
                                    >
                                        <option v-for="(data, index) in list_region" :key="index" :value="data.code">
                                            {{data.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="searchBox">
                                <div class="SearchCombo inline-block">
                                    <label class="searchTitle">미배정사유별</label>
                                    <select class="select_ansimi width150"
                                            v-model="sch_unassign_reason"
                                    >
                                        <option v-for="(data, index) in list_unassign" :key="index" :value="data.code">
                                            {{data.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="searchBox">
                              <div class="SearchCombo inline-block">
                                <label class="searchTitle">최종결과</label>
                                <select class="select_ansimi width150"
                                        v-model="sch_result"
                                >
                                  <option v-for="(data, index) in list_result" :key="index" :value="data.code">
                                    {{data.name}}
                                  </option>
                                </select>
                              </div>
                            </div>
                        </div>
                        <div class="row pdd-top-10">
                            <div class="searchBox">
                              <div class="SearchCombo inline-block">
                                <label class="searchTitle">배정인</label>
                                <select class="select_ansimi width150"
                                        v-model="sch_admin"
                                >
                                  <option v-for="(data, index) in list_admin" :key="index" :value="data.code">
                                    {{data.name}}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="searchBox">
                                <!-- 검색키워드 -->
                                <div class="SearchConditionCombo inline-block">
                                    <label class="searchTitle">키워드</label>
                                    <select class="select_ansimi width150" v-model="searchConditionCombo">
                                        <option v-for="(data, index) in searchConditionConfirmedList" :key="index" :value="data.code">{{data.name}}</option>
                                    </select>
                                    <input type="text" class="input type2 width300 mrg-left-10" v-model="sch_keyword" v-on:keyup.enter="searchFunc" />
                                </div>
                            </div>
                        </div>

                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchFunc">조회</a>
                            <a class="btn8" @click="reset">초기화</a>
                        </div>

                    </div>
                </div>

                <div class="row">
                  <div class="pull-left width-20"><b>전체갯수</b> : {{ totalCount | comma }} 명</div>
                  <div class="width-25 pull-right">
                    <table>
                      <tbody>
                        <tr>
                          <th style="padding:5px">* 확진일 기준</th>
                        </tr>
                        <tr>
                          <th style="width:100px;padding:5px;">1일차 대기자 : </th>
                          <td style="width:10px;padding:5px;background-color:#009211"></td>
                          <th style="width:100px;padding:5px;">2일차 대기자 : </th>
                          <td style="width:10px;padding:5px;background-color:#a9bcf5"></td>
                          <th style="width:100px;padding:5px;">3일차 대기자 : </th>
                          <td style="width:10px;padding:5px;background-color:#f5a9a9"></td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <colgroup>
                                <col width="70" />
                                <col width="70" />
                                <col width="70" />
                                <col width="90" />

                                <col width="90" />
                                <col width="80" />
                                <col width="100" />
                                <col width="80" />
                                <col width="80" />
                                <col width="90" />
                                <col width="80" />
                                <col width="120" />
                                <col width="100" />
                                <col width="100" />
                                <!--<col width="100" />-->
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col />
                            </colgroup>
                            <thead>
                            <tr>
                                <th>순서</th>
                                <th>연번</th>
                                <th>접수연번</th>
                                <th>접수일</th>

                                <th>확진일</th>
                                <th>지역</th>
                                <th>이름</th>
                                <th>성별</th>
                                <th>나이</th>
                                <th>생년월일</th>
                                <th>배정예정</th>
                                <th>시설명</th>
                                <th>배정일</th>
                                <th>배정자</th>
                                <th>배정상태</th>
                               <!-- <th>퇴소/퇴원 현황</th>-->
                                <th>최종결과</th>
                                <th>비고</th>
                                <th>관리</th>
                            </tr>
                            </thead>
                            <tbody v-if="dataList.length>0">
                            <tr v-for="(data, index) in dataList" :key="index" :style="'background-color:'+data.f_check_color+' !important'">
                                <td>
                                    <div v-if="data.f_assign_order_code === 'G1501' || data.f_assign_order_code === '' " style="background-color : #009211 ; width:25px ; height:25px "></div>
                                    <div v-if="data.f_assign_order_code === 'G1502'" style="background-color : #f25500 ; width:25px ; height:25px "></div>
                                </td>
                                <!--<td>{{parseInt(totalCount) - ((parseInt(page)-1)*20 + (parseInt(index)) ) }}</td>-->
                                <td>{{ data.f_idx }}</td>
                                <td>{{ data.f_date_num }}</td>
                                <td>{{ (data.f_reg_date != '0000-00-00 00:00:00') ? $moment(data.f_reg_date).format('YYYY-MM-DD') : '' }}</td>

                                <td>{{ (data.f_confirm_date != '0000-00-00 00:00:00') ? $moment(data.f_confirm_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.f_region }}</td>
                                <td><a @click="popupPatientFunc(data)" class="text-link">{{ data.f_patient_name }}</a></td>
                                <td>{{ data.f_patient_sex }}</td>
                                <td>{{ data.f_patient_age }}</td>
                                <td>{{ (data.f_patient_birth != '0000-00-00 00:00:00') ? $moment(data.f_patient_birth).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.f_assign_type }}</td>
                                <td>{{ data.f_fasility }}</td>
                                <td>{{ (data.f_assign_date != '0000-00-00 00:00:00') ? $moment(data.f_assign_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.f_admin_name }}</td>
                                <td>{{ data.f_assign_state }}</td>

                                <!--<td>{{ data.f_exit_result }}</td>-->
                                <td>{{ data.f_result }}</td>
                                <td>{{ data.f_manage_check_code }}</td>
                                <td>
                                    <template v-if="['G1106', 'G1105'].includes(userDataAdminPermission)">
                                        <button type="button"
                                                class="btn btn-sm btn-inverse mrg-right-5"
                                                @click.stop="copyInfo(data)"
                                        >
                                            정보복사
                                        </button>
                                        <!--
                                        <button type="button"
                                            class="btn btn-sm btn-danger mrg-right-5"
                                            @click="popupUnderlyingDiseaseFunc(data)"
                                        >
                                            기저질환등록
                                        </button>
                                        <button type="button"
                                            class="btn btn-sm btn-info mrg-right-5"
                                            @click="popupSeverityFunc(data)"
                                        >
                                            중증도 변경
                                        </button>
                                        -->
                                        <button type="button"
                                                class="btn btn-sm btn-info mrg-right-5"
                                                @click.stop="popupConfirmedOpenFunc(data)"
                                        >
                                            배정
                                        </button>
                                        <!--
                                        <button type="button"
                                                class="btn btn-sm btn-inverse mrg-right-5"
                                                @click.stop="popupConfirmedTransferredFunc(data)"
                                        >

                                            전원
                                        </button>
                                        -->
                                        <button type="button"
                                                class="btn btn-sm btn-primary mrg-right-5"
                                                @click.stop="searchDetailFunc(data)"
                                        >
                                            수정
                                        </button>
                                        <button type="button"
                                                class="btn btn-sm btn-danger mrg-right-5"
                                                @click.stop="deleteConfirmFunc(data.f_idx)"
                                        >
                                            삭제
                                        </button>
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="17">검색결과가 존재 하지 않습니다.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <paginate v-model="page" :page-count="totalPage" :prev-text="'Prev'" :next-text="'Next'" :containerClass="'pagination'" :click-handler="changePage"></paginate>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />

        <!-- 배정등록 팝업 -->
        <popup-confirmed ref="popupConfirmed" />

        <!-- 전원등록 팝업 -->
        <popup-confirmed-transferred ref="popupConfirmedTransferred" />

        <!-- 기절질환 팝업 -->
        <popup-underlying-disease ref="popupUnderlyingDisease" />

        <!-- 전원등록 팝업 -->
        <popup-severity ref="popupSeverity" />

        <!-- 환자정보 팝업 -->
        <popup-patient ref="popupPatient" />

    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';

import datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale';
import searchTab from '@/components/SearchTab';
import Paginate from 'vuejs-paginate';

import popupConfirmed from '@/components/PopupConfirmed.vue';
import popupConfirmedTransferred from '@/components/PopupConfirmedTransferred.vue';
import popupUnderlyingDisease from '@/components/PopupUnderlyingDisease';
import popupSeverity from '@/components/PopupSeverity.vue';
import PopupPatient from "@/components/PopupPatient";


export default {
    name: 'ConfirmedList',
    data() {
        return {
            ko : ko,
            sch_reg_start_date:'',   // 접수일시작
            sch_reg_end_date:'',   // 접수일끝
            sch_confirm_start_date:'',   // 확진일시작
            sch_confirm_end_date:'',   // 확진일끝
            sch_assign_start_date:'',   // 배정일시작
            sch_assign_end_date:'',   // 배정일끝
            sch_sex : '',         // 성별
            sort_key : '',        // Tab 정보
            sort_data : [
                { code:'', name:'전체' },
                { code:'G0302', name:'배정완료' },
                { code:'G0301', name:'미배정' },
                { code:'G0312', name:'배정진행중' },
                { code:'G0313', name:'재배정' },
                { code:'G0308', name:'완치 (퇴소/퇴원)' },
                { code:'G0309', name:'기타' },
                { code:'G0311', name:'사망' },
                { code:'G1502', name:'배정긴급' }
            ],
            sch_assign_type : '',               // 배정대상
            sch_fasility : '',                  // 시설별 정보
            sch_region : '',                    // 지역별 정보
            sch_unassign_reason : '',           // 미배정사유별 사유별
            sch_result : '',                    // 최종결과
            sch_admin : '',                    // 배정인

            list_assign_type : [],              // 배정대상 리스트
            list_fasility : [],                 // 시설별 리스트
            list_fasility_org : [],             // 시설별 리스트
            list_fasility_popup : [],           // 시설별 리스트
            list_region : [],                   // 지역별 리스트
            list_unassign : [],                 // 미배정사유별 리스트
            list_assign_state : [],             // 배정상태 리스트
            list_move_reason : [],             // 배정상태 리스트
            list_symptom_lv:[],
            list_symptom_state:[],

            list_manager : [],                  // 배정담당자 리스트
            list_sex : [],                      // 성별
            list_result : [],                   // 최종결과
            list_admin : [],                   // 배정인
            list_safecare : [],                   // 재택치료사용

           searchConditionCombo : 'name',
            sch_keyword:'',

            page:1,
            totalPage:0,
            totalCount:0,
            dataList:[],
        }
    },
    components: {PopupPatient, mainHeader, mainFooter, searchTab, Paginate, popupConfirmed, popupConfirmedTransferred, datepicker, popupUnderlyingDisease, popupSeverity },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
        sort_key(){ this.searchFunc() },
        sch_assign_type(){
            this.listFasilitySetFunc()
        },
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {
        if (this.$route.params.info){
            this.sch_reg_start_date = this.$route.params.sch_reg_start_date;
            this.sch_reg_end_date = this.$route.params.sch_reg_end_date;
            this.sch_confirm_start_date = this.$route.params.sch_confirm_start_date;
            this.sch_confirm_end_date = this.$route.params.sch_confirm_end_date;
            this.sch_assign_start_date = this.$route.params.sch_assign_start_date;
            this.sch_assign_end_date = this.$route.params.sch_assign_end_date;
            this.sch_assign_type = this.$route.params.sch_assign_type;
            this.sch_fasility = this.$route.params.sch_fasility;
            this.sch_region = this.$route.params.sch_region;
            this.sch_sex = this.$route.params.sch_sex;
            this.sch_result = this.$route.params.sch_result;
            this.sch_admin = this.$route.params.sch_admin;
            this.sch_unassign_reason = this.$route.params.sch_unassign_reason;
            this.searchConditionCombo = this.$route.params.searchConditionCombo;
            this.sch_keyword = this.$route.params.sch_keyword;
            this.sort_key = this.$route.params.sort_key;
            this.page = this.$route.params.page;

            this.listFasilitySetFunc()
            this.searchListFunc(this.page)
        }else{
            this.reset();
        }
    },
    methods: {
        init(){
            // let formData = new FormData();
            // formData.append('api_name', 'patientCreateCode');
            // this.ajaxPost('', formData,'initResult');

            this.ajaxGet('nocCommon/code?params=', null, 'initResult');
        },
        initResult(response){
            let result = response;

            // 시설별, 시설별 팝업
            let vm = this;
            result.fasilityList.forEach(function(obj){
                vm.list_fasility_org.push(obj)
                vm.list_fasility.push(obj)
                vm.list_fasility_popup.push(obj)
            });
            // this.list_fasility.unshift({ code:'', name:'전체' });
            this.list_fasility_popup.unshift({ code:'', name:'선택' });

            // 지역별
            this.list_region = result.regionList;
            this.list_region.unshift({ code:'', name:'전체' });

            // 미배정사유별
            this.list_unassign = result.unassignList;
            this.list_unassign.unshift({ code:'', name:'전체' });

            // 배정대상
            this.list_assign_type = result.assignTypeList;
            this.list_assign_type.unshift({ code:'', name:'전체' });

            // 배정상태
            this.list_assign_state = result.assignStateList;
            this.list_assign_state.unshift({ code:'', name:'선택' });

            // 전원사유
            this.list_move_reason = result.moveReasonList;
            this.list_move_reason.unshift({ code:'', name:'선택' });

            this.list_symptom_lv = result.symptomLevelList;
            this.list_symptom_lv.unshift({ code:'', name:'선택' });

            this.list_symptom_state = result.symptomStateList;

            // 배정담당자
            this.list_manager = result.managerList;
            this.list_manager.unshift({ code:'', name:'선택' });

            // 성별
            this.list_sex = result.sexList;
            this.list_sex.unshift({ code:'', name:'전체' });

            // 국가
            this.list_country = result.countryList;
            this.list_country.unshift({ code:'', name:'전체' });

            // 최종결과
            this.list_result = result.resultList;
            this.list_result.unshift({ code:'', name:'전체' });

            // 배정인
            this.list_admin = result.adminList;
            this.list_admin.unshift({ code:'', name:'전체' });

            // 배정인
            this.list_safecare = result.safecareList;
            this.list_safecare.unshift({ code:'', name:'전체' });
        },
        listFasilitySetFunc() {
            let vm = this;
            vm.list_fasility = [];
            if (vm.sch_assign_type == ''){ // 전체선택
                this.list_fasility_org.forEach(function(obj) {
                    vm.list_fasility.push(obj)
                });
            } else {
                this.list_fasility_org.forEach(function(obj) {
                    if (vm.sch_assign_type == obj.sort_code || "G0200" == obj.sort_code){ // 배정대상 선택한 값을 시설별 검색 항목에 넣기
                        vm.list_fasility.push(obj);
                    }
                });
            }

            // this.list_fasility.unshift({ code:'', name:'전체' });
        },
        // 초기화
        reset(){
            this.sch_reg_start_date = '';
            this.sch_reg_end_date = '';
            this.sch_confirm_start_date = '';
            this.sch_confirm_end_date = '';
            this.sch_assign_start_date = '';
            this.sch_assign_end_date = '';
            this.sch_assign_type = '';
            this.sch_fasility = '';
            this.sch_region = '';
            this.sch_sex = '';
            this.sch_result = '';
            this.sch_admin = '';
            this.sch_unassign_reason = '';
            this.sch_keyword = '';
            this.sort_key = '';
            this.page = 1;
            this.searchConditionCombo = 'name';

            this.searchFunc();
        },
        searchFunc(){
            this.searchListFunc(1)
        },
        // 리스트 검색
        searchListFunc(pageIndex){
            if (this.sch_reg_end_date && !this.sch_reg_start_date){
                this.showAlert('', '접수 시작일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_reg_start_date && !this.sch_reg_end_date){
                this.showAlert('', '접수 종료일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_reg_start_date && this.sch_reg_end_date &&  this.sch_reg_start_date > this.sch_reg_end_date){
                this.showAlert('', '접수 종료일자를 확인해 주세요.');
                return false;
            }

            let sch_reg_start_date = (this.sch_reg_start_date) ? this.$moment(this.sch_reg_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_reg_end_date = (this.sch_reg_end_date) ? this.$moment(this.sch_reg_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            if (this.sch_confirm_end_date && !this.sch_confirm_start_date){
                this.showAlert('', '확진 시작일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_confirm_start_date && !this.sch_confirm_end_date){
                this.showAlert('', '확진 종료일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_confirm_start_date && this.sch_confirm_end_date &&  this.sch_confirm_start_date > this.sch_confirm_end_date){
                this.showAlert('', '확진 종료일자를 확인해 주세요.');
                return false;
            }

            let sch_confirm_start_date = (this.sch_confirm_start_date) ? this.$moment(this.sch_confirm_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_confirm_end_date = (this.sch_confirm_end_date) ? this.$moment(this.sch_confirm_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            if (this.sch_assign_end_date && !this.sch_assign_start_date){
                this.showAlert('', '배정 시작일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_assign_start_date && !this.sch_assign_end_date){
                this.showAlert('', '배정 종료일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_assign_start_date && this.sch_assign_end_date &&  this.sch_assign_start_date > this.sch_assign_end_date){
                this.showAlert('', '배정 종료일자를 확인해 주세요.');
                return false;
            }

            let sch_assign_start_date = (this.sch_assign_start_date) ? this.$moment(this.sch_assign_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_assign_end_date = (this.sch_assign_end_date) ? this.$moment(this.sch_assign_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            /*
            let formData = new FormData();
            formData.append('api_name', 'patientList');                    // API 이름
            formData.append('sch_reg_start_date', sch_reg_start_date);	        // 접수일시작
            formData.append('sch_reg_end_date', sch_reg_end_date);	            // 접수일끝
            formData.append('sch_confirm_start_date', sch_confirm_start_date);	// 확진일시작
            formData.append('sch_confirm_end_date', sch_confirm_end_date);	    // 확진일끝
            formData.append('sch_assign_start_date', sch_assign_start_date);	    // 배정일시작
            formData.append('sch_assign_end_date', sch_assign_end_date);	        // 배정일끝
            formData.append('sch_fasility', this.sch_fasility);	                // 시설별
            formData.append('sch_region', this.sch_region);	                    // 지역별
            formData.append('sch_sex', this.sch_sex);	                          // 성별
            formData.append('sch_result', this.sch_result);	                    // 최종결과
            formData.append('sch_admin', this.sch_admin);	                      // 배정인
            formData.append('sch_assign_type', this.sch_assign_type);	          // 배정대상
            formData.append('sch_unassign_reason', this.sch_unassign_reason);	    // 미배정사유별
            formData.append('sch_keyword_type', this.searchConditionCombo);	    // 검색 키워드
            formData.append('sch_keyword', this.sch_keyword);	                    // 검색 키워드
            formData.append('sort_key', this.sort_key);	                        // Tab
            formData.append('page_num', pageIndex);	                            // 현재 페이지 번호

            this.page = pageIndex
            this.ajaxPost('', formData,'searchListFuncResult');
            */

            let params = `{
                            "pagination": {
                              "curPage": "${pageIndex}",
                              "pageScale": 20
                            },
                            "search": {
                              "fromRegDt": "${sch_reg_start_date}",
                              "toRegDt": "${sch_reg_end_date}",
                              "fromCfmDt": "${sch_confirm_start_date}",
                              "toCfmDt": "${sch_confirm_end_date}",
                              "fromAssDt": "${sch_assign_start_date}",
                              "toAssDt": "${sch_assign_end_date}",
                              "sex": "${this.sch_sex}",
                              "assignment": "${this.sch_assign_type}",
                              "facility": "${this.sch_fasility}",
                              "borough": "${this.sch_region}",
                              "nonAssignment": "${this.sch_unassign_reason}",
                              "finalResult": "${this.sch_result}",
                              "tapkey": "${this.sort_key}",
                              "keyword": "${this.searchConditionCombo}",
                              "keyword_txt": "${this.sch_keyword}"
                            }
                          }`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocConfirmCase/list?params=' + queryString, null, 'searchListFuncResult');
        },
        searchListFuncResult(response) {
            let result = response;
            this.dataList = [];
            let tmpMap;
            result.list.map(el => {
                tmpMap = {};
                tmpMap.f_assign_order_code = el.assignOrder;
                tmpMap.f_idx = el.seq;
                tmpMap.f_date_num = el.num;
                tmpMap.f_reg_date = el.registDt;
                tmpMap.f_confirm_date = el.cfmDt;
                tmpMap.f_region = el.region;
                tmpMap.f_patient_name = el.name;
                tmpMap.f_patient_sex = el.sex;
                tmpMap.f_patient_age = el.age;
                tmpMap.f_patient_birth = el.birth;
                tmpMap.f_assign_type = el.assignType;
                tmpMap.f_fasility = el.fasility;
                tmpMap.f_assign_date = el.assignDate;
                tmpMap.f_admin_name = el.assignId;
                tmpMap.f_assign_state = el.assignState;
                tmpMap.f_result = el.fanalResult;
                tmpMap.f_manage_check_code = ''; // el.remark; 내용이 너무 길어서 우선 지우기.

                this.dataList.push(tmpMap);
            });
            if (result.count == 0){
                this.totalPage = 0;
                this.totalCount = 0;
            }else{
                this.totalPage = parseInt(result.pagination.maxPage);
                this.totalCount = parseInt(result.count);
            }
        },
        // 페이지 변경
        changePage(){
            this.searchListFunc(this.page)
        },
        excelDownFunc(){

            if (this.sch_reg_end_date && !this.sch_reg_start_date){
                this.showAlert('', '접수 시작일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_reg_start_date && !this.sch_reg_end_date){
                this.showAlert('', '접수 종료일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_reg_start_date && this.sch_reg_end_date &&  this.sch_reg_start_date > this.sch_reg_end_date){
                this.showAlert('', '접수 종료일자를 확인해 주세요.');
                return false;
            }

            let sch_reg_start_date = (this.sch_reg_start_date) ? this.$moment(this.sch_reg_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_reg_end_date = (this.sch_reg_end_date) ? this.$moment(this.sch_reg_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            if (this.sch_confirm_end_date && !this.sch_confirm_start_date){
                this.showAlert('', '확진 시작일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_confirm_start_date && !this.sch_confirm_end_date){
                this.showAlert('', '확진 종료일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_confirm_start_date && this.sch_confirm_end_date &&  this.sch_confirm_start_date > this.sch_confirm_end_date){
                this.showAlert('', '확진 종료일자를 확인해 주세요.');
                return false;
            }

            let sch_confirm_start_date = (this.sch_confirm_start_date) ? this.$moment(this.sch_confirm_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_confirm_end_date = (this.sch_confirm_end_date) ? this.$moment(this.sch_confirm_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            if (this.sch_assign_end_date && !this.sch_assign_start_date){
                this.showAlert('', '배정 시작일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_assign_start_date && !this.sch_assign_end_date){
                this.showAlert('', '배정 종료일자를 선택해 주세요.');
                return false;
            }

            if (this.sch_assign_start_date && this.sch_assign_end_date &&  this.sch_assign_start_date > this.sch_assign_end_date){
                this.showAlert('', '배정 종료일자를 확인해 주세요.');
                return false;
            }

            let sch_assign_start_date = (this.sch_assign_start_date) ? this.$moment(this.sch_assign_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_assign_end_date = (this.sch_assign_end_date) ? this.$moment(this.sch_assign_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';


            console.log("엑셀다운로드");
           /*
            let url= this.rootUrl+'/_excel/excel_patient.php?1=1';

            if(this.sch_reg_start_date && this.sch_reg_end_date){
                url += '&sch_reg_start_date='+this.$moment(this.sch_reg_start_date).format('YYYY-MM-DD');
                url += '&sch_reg_end_date='+this.$moment(this.sch_reg_end_date).format('YYYY-MM-DD');
            }
            if(this.sch_confirm_start_date && this.sch_confirm_end_date){
                url += '&sch_confirm_start_date='+this.$moment(this.sch_confirm_start_date).format('YYYY-MM-DD');
                url += '&sch_confirm_end_date='+this.$moment(this.sch_confirm_end_date).format('YYYY-MM-DD');
            }
            if(this.sch_assign_start_date && this.sch_assign_end_date){
                url += '&sch_assign_start_date='+this.$moment(this.sch_assign_start_date).format('YYYY-MM-DD');
                url += '&sch_assign_end_date='+this.$moment(this.sch_assign_end_date).format('YYYY-MM-DD');
            }

            if(this.searchConditionCombo && this.sch_keyword){
                url += '&sch_keyword_type='+this.searchConditionCombo;
                url += '&sch_keyword='+this.sch_keyword;
            }

            url += '&sch_fasility='+this.sch_fasility;
            url += '&sch_region='+this.sch_region;
            url += '&sch_unassign_reason='+this.sch_unassign_reason;
            url += '&sch_assign_state='+this.sort_key;

            this.excelExport(url);


            param 들어갈 것들
                              "fromRegDt": "${sch_reg_start_date}",
                              "toRegDt": "${sch_reg_end_date}",
                              "fromCfmDt": "${sch_confirm_start_date}",
                              "toCfmDt": "${sch_confirm_end_date}",
                              "fromAssDt": "${sch_assign_start_date}",
                              "toAssDt": "${sch_assign_end_date}",
                              "sex": "${this.sch_sex}",
                              "assignment": "${this.sch_assign_type}",
                              "facility": "${this.sch_fasility}",
                              "borough": "${this.sch_region}",
                              "nonAssignment": "${this.sch_unassign_reason}",
                              "finalResult": "${this.sch_result}",
                              "tapkey": "${this.sort_key}",
                              "keyword": "${this.searchConditionCombo}",
                              "keyword_txt": "${this.sch_keyword}"
                              
                              
                              "fromRegDt": "2021-01-01",
                              "toRegDt": "2021-01-01",
                              "fromCfmDt": "",
                              "toCfmDt": "",
                              "fromAssDt": "",
                              "toAssDt": "",
                              "sex": "",
                              "assignment": "",
                              "facility": "",
                              "borough": "",
                              "nonAssignment": "",
                              "finalResult": "",
                              "tapkey": "",
                              "keyword": "all",
                              "keyword_txt": ""
                              
            */
            let params = `{
                            "search": {
                              "fromRegDt": "${sch_reg_start_date}",
                              "toRegDt": "${sch_reg_end_date}",
                              "fromCfmDt": "${sch_confirm_start_date}",
                              "toCfmDt": "${sch_confirm_end_date}",
                              "fromAssDt": "${sch_assign_start_date}",
                              "toAssDt": "${sch_assign_end_date}",
                              "sex": "${this.sch_sex}",
                              "assignment": "${this.sch_assign_type}",
                              "facility": "${this.sch_fasility}",
                              "borough": "${this.sch_region}",
                              "nonAssignment": "${this.sch_unassign_reason}",
                              "finalResult": "${this.sch_result}",
                              "tapkey": "${this.sort_key}",
                              "keyword": "${this.searchConditionCombo}",
                              "keyword_txt": "${this.sch_keyword}"
                            }
            }`;

            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocConfirmCase/listDown?params=' + queryString, null, 'downloadExcel');

        },
        downloadExcel(response) {
            if (!response || !response.list || response.list.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.list; // 데이터 가져오기
            console.log(dataList);
            let excelData = [];

            // 엑셀 헤더 추가
            excelData.push(['NO', '연번', '접수연번', '접수일', '확진일', '지역', '이름', '성별', '나이', '배정예정', '시설명', '배정일', '배정자', '배정상태', '최종결과', '비고']);

            // 엑셀 데이터 추가
            dataList.forEach((data, index) => {
                excelData.push([
                    index + 1,
                    data.연번,
                    data.접수연번,
                    data.접수일,
                    data.확진일,
                    data.지역,
                    data.이름,
                    data.성별,
                    data.나이,
                    data.배정예정,
                    data.시설명,
                    data.배정일,
                    data.배정자,
                    data.배정상태,
                    data.최종결과,
                    data.비고
                ]);
            });


            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정_확진자관리.xlsx');
        },

        // 중증도
        excelSympDownFunc(){
          let url= this.rootUrl+'/_excel/excel_statistics_symp_daily_check.php';
          this.excelExport(url);
        },
        // 상세페이지
        searchDetailFunc(obj){
            let data={};
            data.sch_reg_start_date = this.sch_reg_start_date;
            data.sch_reg_end_date = this.sch_reg_end_date;
            data.sch_confirm_start_date = this.sch_confirm_start_date;
            data.sch_confirm_end_date = this.sch_confirm_end_date;
            data.sch_assign_start_date = this.sch_assign_start_date;
            data.sch_assign_end_date = this.sch_assign_end_date;
            data.sch_assign_type = this.sch_assign_type;
            data.sch_fasility = this.sch_fasility;
            data.sch_region = this.sch_region;
            data.sch_sex = this.sch_sex;
            data.sch_result = this.sch_result;
            data.sch_admin = this.sch_admin;
            data.sch_unassign_reason = this.sch_unassign_reason;
            data.sch_keyword = this.sch_keyword;
            data.searchConditionCombo = this.searchConditionCombo;
            data.sort_key = this.sort_key;
            data.info = (obj) ? obj : {};
            data.adminName = this.adminName;
            data.page = this.page;
            this.$router.push({ name: 'AdminConfirmedDetail', params: {data} })
        },
        // 삭제 컨펀창
        deleteConfirmFunc(f_idx){
            this.showConfirm("", "삭제 하시겠습니까?", "deleteConfirm", f_idx);
        },
        // 삭제 처리
        deleteConfirm(f_idx){
            let data = {
                "fIdx": f_idx,
                "adminId": this.userDataAdminId,
            }
            /*
            let formData = new FormData();
            formData.append('api_name', 'patientDelete');
            formData.append('f_idx', f_idx);
            formData.append('f_admin_id', this.userDataAdminId);
            */

            this.ajaxPost('nocConfirmCase/delete', data,'deleteConfirmResult');
        },
        // 삭제 처리 결과
        deleteConfirmResult() {
            this.showAlert('', '삭제처리 되었습니다.', "searchListFunc", 1);
        },
        // 배정등록
        popupConfirmedOpenFunc(obj){
            console.log(this);
            this.$refs.popupConfirmed.init(true, obj, this.list_fasility_popup, this.list_assign_state, this.list_manager , this.list_safecare , this.page);
        },
        // 전원등록
        popupConfirmedTransferredFunc(obj){
            this.$refs.popupConfirmedTransferred.init(true, obj, this.list_fasility_popup, this.list_move_reason, this.page)
        },
        // 기절질환 등록 팝업
        popupUnderlyingDiseaseFunc(obj){
            this.$refs.popupUnderlyingDisease.init(true, obj, this.page)
        },
        // 중증도 변경
        popupSeverityFunc(obj){
            this.$refs.popupSeverity.init(true, obj, this.list_symptom_state, this.page)
        },
        // 환자정보 변경
        popupPatientFunc(obj){
            this.$refs.popupPatient.init(true, obj)
        },
    }
}
</script>

<style scoped>
.bg-red {
  background-color: #B40404;
  padding:10px;
  border:1px solid red;
}
.bg-blue {
  background-color: #08298A;
  padding:10px;
  border:1px solid red;
}
</style>
