<template>
    <div class="PopupConfirmed" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3># 배정등록 </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="20%">
                            <col width="80%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">연번</th>
                            <td class="text-left">{{ data.f_idx }}</td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left">{{ data.f_patient_name }}</td>
                        </tr>
                        <tr>
                            <th class="field">생년월일</th>
                            <td class="text-left">{{$moment(data.f_patient_birth).format('YYYY-MM-DD') + '('+data.f_patient_sex +' / ' + data.f_patient_age +')' }}</td>
                        </tr>
                        <tr>
                            <th class="field">배정일</th>
                            <td class="text-left">
                                <div class="dataGroup">
                                    <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" :disabled-dates="disabledDates" v-model="f_assign_date"></datepicker>
                                    <span class="icon_calendar"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">배정담당자</th>
                            <td class="text-left">
                                <select class="select_ansimi width150"
                                        v-model="f_manager"
                                >
                                    <option v-for="(data, index) in list_manager" :key="index" :value="data.code">
                                        {{data.name}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">시설명</th>
                            <td class="text-left">
                              <select class="select_ansimi width150"
                                      v-model="f_fasility"
                              >
                                <option v-for="(data, index) in list_fasility_org" :key="index" :value="data.code">
                                  {{data.name}}
                                </option>
                              </select>

                            </td>
                        </tr>
                        <tr>
                            <th class="field">배정상태</th>
                            <td class="text-left">
                              <select class="select_ansimi width150"
                                      v-model="f_assign_state"
                              >
                                <option v-for="(data, index) in list_assign_state" :key="index" :value="data.code">
                                  {{data.name}}
                                </option>
                              </select>

                            </td>
                        </tr>
                        <tr v-if="f_fasility == '181'">
                          <th class="field">재택치료 사유</th>
                          <td class="text-left">

                            <select class="select_ansimi width150"
                                    v-model="f_safecare"
                            >
                              <option v-for="(data, index) in list_safecare" :key="index" :value="data.code">
                                {{data.name}}
                              </option>
                            </select>

                          </td>
                        </tr>
                        <tr v-if="f_safecare == 'G1405'">
                          <th class="field" >기타사유</th>
                          <td class="text-left">
                            <input type="text" class='input type2 width-100' v-model="f_safecare_etc_reason" />
                          </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
    import datepicker from 'vuejs-datepicker';
    import {ko} from 'vuejs-datepicker/dist/locale'

    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 90);
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 90);

    export default {
        name: 'PopupConfirmed',
        data() {
            return {
                disabledDates: {
                    to: minDate,
                    from: maxDate
                },
                ko : ko,
                isVisible:false,
                data:[],
                f_assign_date : '',                 // 배정일
                f_manager : '',                     // 배정담당자 정보
                list_manager : [],                  // 배정담당자 리스트
                f_fasility : '',                    // 시설별 정보
                f_safecare : '',                    // 재택치료사유
                f_safecare_etc_reason : '',         // 재택치료사유 - 그외 이유
                list_fasility : [],                 // 시설별 리스트
                list_safecare : [],                 // 재택치료사유
                list_fasility_org : [],             // 시설별 리스트
                f_assign_state : '',                // 배정상태
                list_assign_state : [],             // 배정상태 리스트
                page:'',                            // 페이지
            }
        },
        components: {  datepicker },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
            f_manager(){
              this.listFasilitySetFunc()
            },
            f_fasility(_new) {
                const selectedData = this.list_fasility.find(item => item.code === _new);
                if (selectedData) {
                    this.f_fasility_upper_code = selectedData.upperCode;
                } else {
                    this.f_fasility_upper_code = ''; // 선택된 항목이 없을 경우 초기화
                }
            }
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {

        },
        methods: {
            init(isVisible, obj, list_fasility, list_assign_state, list_manager , list_safecare,   page){
                let params = `{"fIdx":${obj.f_idx}}`;
                let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
                this.ajaxGet('nocConfirmCase/detailAssign?params=' + queryString, null, 'initResult');

                this.isVisible=isVisible;

                this.list_manager = list_manager;
                this.list_fasility = list_fasility;
                this.list_assign_state = list_assign_state;
                this.list_safecare = list_safecare;

                this.page = page;

                /*
                this.data = obj;
                this.f_fasility = obj.f_fasility_code;
                this.f_safecare = obj.f_safecare_code;
                this.f_safecare_etc_reason = obj.f_safecare_etc_reason;
                this.f_assign_state = obj.f_assign_state_code;
                this.f_assign_date = (obj.f_assign_date != '0000-00-00 00:00:00') ? this.$moment(obj.f_assign_date).format('YYYY-MM-DD') : this.$moment(new Date()).format('YYYY-MM-DD');

                this.list_fasility_org = list_fasility;

                this.f_manager = obj.f_manager_code;
                 */
            },
            initResult(response){
                let result = response.list;
                console.log(result);

                this.data.f_idx = result.fidx;
                this.data.f_patient_name = result.fpatientName;
                this.data.f_patient_birth = result.fpatientBirth;
                this.data.f_patient_sex = result.fpatientSex;
                this.data.f_patient_age = result.fpatientAge;
                this.f_assign_date = (result.fassignDate != '0000-00-00 00:00:00') ? this.$moment(result.fassignDate).format('YYYY-MM-DD') : this.$moment(new Date()).format('YYYY-MM-DD');

                this.f_manager = result.fmanagerCode;
                this.f_fasility = result.ffasilityCode;
                this.f_assign_state = result.fassignStateCode;
                this.f_safecare = result.fsafecareCode;
                this.f_safecare_etc_reason = result.fsafecareEtcReason;

                console.log(this.data);
            },
            sendFunc(){
                if (!this.f_assign_date){
                    this.showAlert('', '배정일을 선택해 주세요.');
                    return false;
                }

                if (!this.f_manager){
                    this.showAlert('', '배정담당자를 선택해 주세요.');
                    return false;
                }

                if (!this.f_fasility){
                    this.showAlert('', '시설명을 선택해 주세요.');
                    return false;
                }


                if (!this.f_assign_state){
                    this.showAlert('', '배정상태를 선택해 주세요.');
                    return false;
                }

                if (this.f_fasility == '181') {
                  if (!this.f_safecare) {
                    this.showAlert('', '재택치료 사유를 선택해 주세요.');
                    return false;
                  }
                }

                let data = {
                    "fIdx": this.data.f_idx,
                    "fAssignStateCode": this.f_assign_state,
                    "fAssignDate": this.f_assign_date,
                    "fFasilityCode": this.f_fasility,
                    "fFasilityTypeCode": this.f_fasility_upper_code,
                    "fManagerCode": this.f_manager,
                    "adminId": this.userDataAdminId,
                }

                /*
                let formData = new FormData();
                formData.append('api_name', 'patientAssignCreate');
                formData.append('f_idx', this.data.f_idx);
                formData.append('f_assign_date', this.$moment(this.f_assign_date).format('YYYY-MM-DD'));
                formData.append('f_manager', this.f_manager);
                formData.append('f_fasility', this.f_fasility);
                formData.append('f_safecare', this.f_safecare);
                formData.append('f_safecare_etc_reason', this.f_safecare_etc_reason);
                formData.append('f_assign_state', this.f_assign_state);
                formData.append('f_admin_id', this.userDataAdminId);
                */

                this.ajaxPost('nocConfirmCase/updateAssign', data,'sendFuncResult');
            },
            sendFuncResult(response) {
                this.showAlert('', '저장 되었습니다.',"close");
            },
            listFasilitySetFunc(){
                let vm = this;
                vm.list_fasility_org = [];
                if (vm.f_manager == '' || vm.f_manager == 'G0104' || vm.f_manager == 'G0105'){ // 전체선택
                    this.list_fasility.forEach(function(obj){
                        vm.list_fasility_org.push(obj)
                    });
                } else {
                    var strCode = '';

                    if(vm.f_manager == 'G0101'){ // 서울시
                        strCode = 'G0202';
                    }
                    if(vm.f_manager == 'G0102'){ // 중수본
                        strCode = 'G0203';
                    }
                    if(vm.f_manager == 'G0103'){ // 자치구
                        strCode = 'G0201';
                    }
                    this.list_fasility.forEach(function(obj){
                        if (obj.upperCode == strCode){ // 배정대상 선택한 값을 시설별 검색 항목에 넣기
                            vm.list_fasility_org.push(obj)
                        }
                    });
              }

              // this.list_fasility.unshift({ code:'', name:'전체' });
            },
            close(){
                this.isVisible=false;
                this.$parent.searchListFunc(this.page);
            }
        }
    }
</script>
<style scoped>
    .prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 500px;
        background: #fff;
        padding: 0 20px 20px;}
    .dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}
</style>
